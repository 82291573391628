import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import Nav from './components/Nav';
import Global from './global';
import MetaTags from 'react-meta-tags';

function RedirectToGlobal({history}) {
  useEffect(() => {
    setTimeout(() => {
      history.replace('/global');
    }, 500);
  }, []);

  return (
    <div>Loading ...</div>
  );
}


function App() {
  return (
    <Router>
      <Layout className="app">
        <MetaTags>
          <title>Crypto Jin - Trade and Market Information</title>
          <meta name="title" content="Crypto Jin - Trade and Market Information" />
          <meta name="description" content="Trade cryptocurrencies and get up to date crypto currency markets information." />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </MetaTags>
        <Nav />
        <Route path={'/'} exact component={RedirectToGlobal} />
        <Route path={'/global'} component={Global} />
      </Layout>
    </Router>
  );
}

export default App;
