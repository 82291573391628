import React from 'react';
import { Menu } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    header: {
        width: '100%'
    },
    title: {
        float: 'left',
        display: 'inline-block',
        fontWeight: 'bold',
        lineHeight: '46px',
        margin: '0 20px',
        color: 'rgba(0, 0, 0, 0.65)',
        '&:hover': {
            color: 'rgba(0, 0, 0, 0.65)'
        }
    },
    menuRight: {
        float: 'right'
    }
});

export default function Nav() {
    const classes = useStyles();

    const enableSignupLogin = false;

    return (
        <div className={classes.header}>
            <Link to="/" className={classes.title}>CRYPTO JIN</Link>
            <Menu theme="light" mode="horizontal">
                <Menu.Item key="markets">
                    <Link to={'/global'}>
                        <GlobalOutlined />
                        <span>Global Markets</span>
                    </Link>
                </Menu.Item>
                {enableSignupLogin ? (
                    <>
                        <Menu.Item key="signup" className={classes.menuRight}>Signup</Menu.Item>
                        <Menu.Item key="login" className={classes.menuRight}>Login</Menu.Item>
                    </>
                ) : null}
            </Menu>
        </div>
    );
}
