import React, { useState, useEffect } from 'react';
import { Layout, Table } from 'antd';
import { createUseStyles } from 'react-jss';
import { Currency, CurrencySmart, NumberSmart, PercentRaw } from '../components/formatters';
import { assetsUrl, marketsApiUrl } from '../config';

const { Content } = Layout;

const useStyles = createUseStyles({
    icon: {
        width: '30px',
        height: '30px'
    }
});

export default function Global() {
    const [assets, setAssets] = useState([]);

    const classes = useStyles();

    const columns = [
        {
            title: 'Rank',
            dataIndex: 'rank',
            key: 'rank'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                let imageUrl = `${assetsUrl}/assets/${record.symbol.toLowerCase()}.png`;
                return (
                    <div>
                        <img src={imageUrl} className={classes.icon} />
                        {record.name} ({record.symbol})
                    </div>
                )
            }
        },
        {
            title: 'Price',
            dataIndex: 'priceUsd',
            key: 'priceUsd',
            render: (text) => {
                return <Currency value={text} symbol={'$'} />
            }
        },
        {
            title: 'Market Cap',
            dataIndex: 'marketCapUsd',
            key: 'marketCapUsd',
            render: (text) => {
                return <CurrencySmart value={text} symbol={'$'} />
            }
        },
        {
            title: 'Supply',
            dataIndex: 'supply',
            key: 'supply',
            render: (text) => {
                return <NumberSmart value={text} />
            }
        },
        {
            title: 'Volume (24hr)',
            dataIndex: 'volumeUsd24Hr',
            key: 'volumeUsd24Hr',
            render: (text) => {
                return <CurrencySmart value={text} symbol={'$'} />
            }
        },
        {
            title: 'Change (24hr)',
            dataIndex: 'changePercent24Hr',
            key: 'changePercent24Hr',
            render: (text) => {
                return <PercentRaw value={text} color={true} />
            }
        }
    ];

    const getAssets = async () => {
        return await fetch(`${marketsApiUrl}/assets`).then(response => response.json()).then(data => data.assets);
    }

    useEffect(() => {
        getAssets().then((items) => {
            items.sort((a, b) => parseInt(a.rank) < parseInt(b.rank) ? -1 : 1);
            setAssets(items);
        });
    }, []);

    return (
        <Content style={{width: '80%', margin: '0 auto'}}>
            <h1>GLOBAL MARKETS</h1>

            <Table dataSource={assets} columns={columns} pagination={{hideOnSinglePage: true, pageSize: 200}} />
        </Content>
    )
}
