const common = {
    assetsUrl: 'https://assets.cryptojin.com'
};

const dev = {
    marketsApiUrl: 'http://localhost:5000'
};

const prod = {
    marketsApiUrl: 'https://markets-api.cryptojin.com'
};

const config = process.env.REACT_APP_STAGE === 'prod'
    ? Object.assign({}, common, prod)
    : Object.assign({}, common, dev);

export const assetsUrl = config.assetsUrl;
export const marketsApiUrl = config.marketsApiUrl;
