import React from 'react';
import numbro from 'numbro';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    positive: {
        color: 'green'
    },
    negative: {
        color: 'red'
    }
});

export function Currency({value, symbol}) {
    return numbro(value).formatCurrency({
        currencySymbol: symbol,
        mantissa: 2
    });
}

export function CurrencySmart({value, symbol}) {
    return numbro(value).formatCurrency({
        currencySymbol: symbol,
        mantissa: 2,
        average: true
    });
}

export function NumberSmart({value}) {
    return numbro(value).format({
        mantissa: 2,
        average: true
    });
}

export function PercentRaw({value, color}) {
    const classes = useStyles();
    if (!value) {
        return '';
    }
    var fmtNum = numbro(value).format({
        mantissa: 2
    }) + '%';

    if (color) {
        const className = value < 0 ? classes.negative : classes.positive;

        return <span className={className}>{fmtNum}</span>
    }
    else {
        return fmtNum;
    }
}
